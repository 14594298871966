<template>
  <div class="spec-wrapper">
    <OverlayLoad :show="overlayShow"></OverlayLoad>
    <van-popup v-model="popupShow" position="bottom" @click-overlay="handleClose" safe-area-inset-bottom closeable
      :style="{ height: '90%' }">
      <div class="spec-content">
        <spec-header :selectedSpec="selectedSpec"></spec-header>
        <div class="spec-content-middle">
          <spec-lump>
            <spec-list-choose :list="specList" @loadAfter="handleSpecChange" />
          </spec-lump>
          <spec-lump>
            <div class="field-wrapper">
              <div class="field-label">
                购买数量<span v-if="selectedSpec.limitNum" class="title-tip">限购{{ selectedSpec.limitNum }}件</span>
              </div>
              <div class="field-content">
                <van-stepper :disable-minus="payCount <= 1"
                  :disable-plus="selectedSpec.limitNum ? payCount >= selectedSpec.limitNum : false"
                  :disabled="!selectedSpec.id" disable-input @overlimit="handleOverlimit"
                  v-model="payCount" />
              </div>
            </div>
          </spec-lump>
          <spec-lump>
            <div class="field-wrapper">
              <div class="field-label">
                <span class="red">请仔细核对充值账户，若充值错误，将无法退回</span>
              </div>
            </div>
          </spec-lump>
          <spec-lump>
            <div class="field-wrapper">
              <div class="field-label">
                <span class="red">*</span><span style="font-weight: bold;">充值账户</span>
              </div>
              <div class="field-content">
                <van-field 
                  v-model="rechargeAccount"
                  placeholder="请输入充值账户"
                  :rules="[{ required: true, message: '请输入充值账户' }]"
                  style="border-radius: 1rem; background-color: #f8f8f8;margin-left: 1rem;width: 100%;"
                  maxlength="50"
                />
              </div>
            </div>
          </spec-lump>
        </div>

        <div class="pay-button-wrapper">
          <van-goods-action>
            <van-goods-action-button v-if="buyNowShow" type="danger" :disabled="cantBuy" :text="textBuy"
              @click="handlebuyNowClick" />
          </van-goods-action>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import SpecListChoose from "./SpecListChoose.vue";
import SpecLump from "./SpecLump.vue";
import SpecHeader from "./SpecHeader.vue";
import OverlayLoad from "./OverlayLoad.vue";
import {
  getSpecInfo,
  isLogin,
  isNoEmptyArr,
} from "./index.action";
import { Toast } from "vant";
export default {
  components: {
    OverlayLoad,
    SpecHeader,
    SpecListChoose,
    SpecLump
  },
  props: {
    goodsType: {
      type: String
    },
    productType: {
      type: String
    },
    list: {
      type: Array
    },
    channelType: {
      type: Number, // 1 全品 2 蛋糕 3 电影
      default: 1
    },
    queryForm: {
      // 查询商品信息所需参数
      type: Object,
      default: function () {
        return {
          cardId: "",
          cityCode: "",
          brandId: "",
          productId: ""
        };
      }
    }
  },
  data() {
    return {
      rechargeAccount: '',//充值账号
      notesText: '', // 备注信息
      buyNowShow: true, // 立即购买按钮显示
      specDataLoad: false, // 规格数据加载锁
      overlayShow: false,
      popupShow: false, // 是否打开展示板
      sendSelectType: 4, //配送类型 默认 无需物流
      shopInfo: {}, // 店铺信息
      isDistribution: null,
      selectedSpec: {}, // 当前选择的规格信息
      specList: [], // 规格信息数组
      cantBuy: false,// 不能买和加入购物车
      textBuy: '立即购买',
      payCount: 1, // 购买数量
      target: null,
      validate_delivery_dates: [],//商家配送数据，计算运费使用
      showAddressBar: 0,// 是否显示结算页面的上方地址栏. 快递商品可以显示,蛋糕类不支持快递的不显示
    };
  },
  watch: {
    list: {
      handler: function (newVal) {
        if (isNoEmptyArr(newVal)) {
          this.specList = newVal;
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * 规格选择框打开方法
     */
    async open(typeStr) {
      this.buyNowShow = true;
      this.overlayShow = true;
      if (isNoEmptyArr(this.list)) {
        setTimeout(() => {
          this.overlayShow = false;
          this.popupShow = true;
        }, 500);
      } else {
        const res = this.$api.getProductDetail(this.queryForm);
        const dataList = await getSpecInfo();
        this.overlayShow = false;
        this.specList = dataList;
        this.popupShow = true;
      }
    },

    handleOverlimit(val) {
      if (this.payCount <= 1) {
        if (val == 'plus') {
          Toast("购买数量最多1件");
        } else
          Toast("购买数量最少1件");
        return
      } else if (this.payCount >= this.selectedSpec.limitNum) {
        Toast(`最大购买数量为${this.payCount}件`);
        return
      }
    },
    /**
     * 立即购买
     */
    handlebuyNowClick() {
      if (this.rechargeAccount.trim() ===''){
        //充值账户必填
        Toast('请填写充值账户')
        return
      }
      if (this.rechargeAccount.length>50){
        Toast('充值账户内容过多,请确认')
        return
      }
      if (Number(this.selectedSpec.status) === 3) {
        Toast('该商品补货中！请选择其他商品')
        return
      }
      if (Number(this.selectedSpec.status) === 2) {
        Toast('该商品已下架！请选择其他商品')
        return
      }
      this.onSubmit("buyNow");
    },
    onSubmit(type) {
      if (!isLogin()) return;
      this.$emit("submit", {
        type: type,
        selectedSpec: {
          ...this.selectedSpec,
          count: this.payCount // 购买数量
        }, // 当前选择的规格
        deliveryData: {
          sendSelectType: this.sendSelectType, // 配送类型
          showAddressBar: this.showAddressBar, // 是否显示结算页面的上方地址栏. 快递商品可以显示,蛋糕类不支持快递的不显示
        },
        rechargeAccount: this.rechargeAccount
      });
      this.popupShow = false;
    },
    /**
     * 选择规格
     */
    handleSpecChange(record) {
      this.selectedSpec = record || {};
      if (!this.specDataLoad) {
        // loadAfter方法执行时判断
        this.specDataLoad = true;
      } else {
        this.onceLoad();
      }
      this.payCount = 1;
      this.$emit("specChange", { selectedSpec: this.selectedSpec, count: this.payCount });
      if (Number(this.selectedSpec.status) === 3) {
        this.cantBuy = true;
        this.textBuy = '补货中';
      }
    },

    handleClose() {
      this.popupShow = false;
    },
    onceLoad() {
      this.overlayShow = true;
      setTimeout(() => {
        this.overlayShow = false;
      }, 400);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./SpecPopupRecharge";
</style>
