<template>
  <div>
    <div class="good height-100">
      <!-- 轮播图 -->
      <van-swipe class="my-swipe img" :autoplay="10000" :style="{ height: bannerHeight + 'px' }"
        indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
          <van-image :src="item" alt="" class="img" :style="{ height: bannerHeight + 'px' }" fit="cover" />
        </van-swipe-item>
      </van-swipe>
      <div class="card">
        <div class="p-tb-10">
          <span class="goods-price-item"><span>{{ selectPrice }}</span> 点</span>
        </div>
        <div style="font-weight: 600; font-size: 1.05rem; margin-top: 0.6rem">
          <span class="titletag" v-if="getTitleTag()" :style="{ background: getTitleTag().colorCode }">
            {{ getTitleTag().name }}
          </span>
          【{{ detail?.brandName }}】{{ detail?.productName }}
        </div>
        <div class="tags">
          <span class="tag" v-for="(val, index) in getTagList()" :key="index">{{ val.name }} </span>
        </div>
      </div>
      <div class="card">
        <div class="p-5 jcsb m-tb-10">
          <DetailsSpecItem label="品牌" :text="detail?.brandName" @click="handleProductList"></DetailsSpecItem>
          <DetailsSpecItem label="规格" :text="selectGoods" @click="specPopupOpen"></DetailsSpecItem>
        </div>
      </div>
      <div class="card">
        <div class="context">
          <div class="icon"></div>
          商品简介
        </div>
        <div style="margin: 0.8rem 0; font-size: 0.8rem; color: #666">{{ detail?.description }}</div>
      </div>
      <van-image style="width: 100%" v-if="detail?.noticeImage" :src="detail?.noticeImage">
      </van-image>
      <van-goods-action>
        <van-goods-action-icon icon="cart-o" text="购物车" @click="goCar" />
        <van-goods-action-button type="danger" :disabled="cantBuy" :text="textBuy"
          @click="() => specPopupOpen('buyNow')" />
      </van-goods-action>
    </div>
    <SpecPopupRecharge ref="SpecPopupRecharge" v-if="productDetaiLoad" :goodsType="detail.category1Id && detail.category1Id == '5' ? 'cake' : 'normal'"
      :list="specsLsit" :product-type="productType"
      :venue-list="detail?.venueList" :current-distribution="detail.distributionRuleId" :query-form="detailform"
      :channel-type="2" @submit="handleSpecSubmit"></SpecPopupRecharge>
  </div>
</template>

<script>
import DetailsSpecItem from "./DetailsSpecItem.vue";
import SendType from "../../components/goods/SendType.vue";
import SpecPopupRecharge from "../../components/SpecPopup/SpecPopupRecharge.vue";
import OverlayLoad from "../../components/SpecPopup/OverlayLoad.vue";
import { goMyCart, getGoodsDetail, submitBuyGoodsInfo } from "./index.action";
import Utils from "../../utils";
export default {
  name: "DetailsRecharge",
  props: {},
  data() {
    return {
      bannerList: [], // banner图
      specsLsit: [], // 可选规格列表
      loading: false,
      productDetaiLoad: false, // 详情信息,是否加载完成
      detail: null,
      bannerHeight: 0,
      //商品规格
      show: false,
      selectGoods: "", // 展示所选规格的商品
      sendTypeStr: "", // 展示的配送信息
      detailform: {
        cardId: "",
        cityCode: "",
        brandId: "",
        productId: ""
      },
      cantBuy: false,// 不能买和加入购物车
      textBuy: '立即购买',

      ShopDetail: null,
      userId: "",
      chousetype: "",
      selectPrice: '',
      selectMarketprice: "",
      goodNum: 1, // 商品数量
      specId: this.$route.query.specId, // 规格 id
      dsUserId: "",
      dsId: "",
      shopDetail: "",
      shopId: "",
      shopName: "",
      consigneeName: "",
      consigneePhone: "",
      productType: "", //0 票 1 蛋糕 2 其他
      delivery_amount: "0",
    };
  },
  components: { 
    DetailsSpecItem,
    SendType,
    SpecPopupRecharge,
    OverlayLoad 
  },

  mounted() {
    this.init();
    this.bannerHeight = (document.body.clientWidth || window.innerWidth || document.documentElement.clientWidth)
    var timer = setInterval(() => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
      clearInterval(timer);
    }, 200)
  },

  computed: {
  },

  methods: {
    goBack() {
      this.$router.back();
    },
    getTitleTag() {
      let Tag = undefined
      if (this.detail?.labelList) {
        this.detail?.labelList.forEach(element => {
          if (element.smallLabelType == '1') {
            Tag = element
          }
        });
      }
      return Tag
    },
    getTagList() {
      let Tag = []
      if (this.detail?.labelList) {
        this.detail?.labelList.forEach(element => {
          if (element.smallLabelType !== '1') {
            Tag.push(element)
          }
        });
      }
      return Tag
    },
    formNumber(val) {
      if (val) {
        return parseFloat(val).toFixed(2)
      } else return ''

    },
    handleProductList() {
      if (this.detail && this.detail.id) {
        const { category1Id, category2Id, brandName } = this.detail;
        const timeStamp=new Date().getTime()
        this.$router.push({
          // path: "/ProductList",
           path:`/ProductList/${timeStamp}/${brandName}`,
          // query: {
          //   brandName: brandName,
          //   category1Id: category1Id,
          //   category2Id: category2Id,
           
          // },
          // params: { time: 123 },
          // reflesh: true
        });
      // this.$router.push(path);
      }


    },
    init() {
      this.userId = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "";
      this.detailform.cardId = localStorage.getItem("cardId");
      this.detailform.cityCode = localStorage.getItem("cityCode");
      this.detailform.productId = this.$route.query.id;
      this.detailform.brandId = this.$route.query.brandId;
      this.getProductDetail();
    },
    // 获取商品详情
    async getProductDetail() {
      this.loading = true;
      const goodsData = await getGoodsDetail(this.detailform, this.specId);
      this.productDetaiLoad = true;
      if (goodsData) {
        this.detail = goodsData.target;
        this.productType = this.detail.productType
        if (this.detail.venueList && this.detail.venueList.length > 0) {
          this.detail.venueList[0].checked = true
        }
        this.specsLsit = goodsData.specsArr;
        this.setGoodsInfo(goodsData.defaultSpecs);
      } else {
        var timer = setInterval(() => {
          this.$router.back();
          clearInterval(timer);
        }, 1000)
      }
      this.loading = false;
    },
    // 去购物车
    goCar() {
      goMyCart(2);
    },
    /**
     * 打开规格选择内容页
     */
    specPopupOpen(params) {
      this.$refs.SpecPopupRecharge.open(params);
    },
    /**
     * 规格菜单点击加入【立即购买】执行函数
     */
    async handleSpecSubmit({ type, selectedSpec, deliveryData, rechargeAccount }) {
      this.setGoodsInfo(selectedSpec, selectedSpec.count);
      this.loading = true;
      await submitBuyGoodsInfo(
        type,
        { userId: this.userId, ...this.detailform },
        deliveryData,
        selectedSpec,
        this.detail,
        {rechargeAccount: rechargeAccount}
      );
      this.loading = false;
    },
    /**
     * 商品相关的data数据回填
     */
    setGoodsInfo(specInfo, count = 1) {
      const specInfo_ = specInfo || {};
      const { id, specifications, showPrice, ticketPrice, bannerList, coverImage, htmEditorList } = specInfo_;
      this.goodNum = count; // 购买数量
      this.specId = id || "";
      if (this.productType === '0') {
        this.selectGoods = ticketPrice ? `${ticketPrice}` : "暂无商品";
        this.chousetype = ticketPrice || "暂无商品";
      } else {
        this.selectGoods = specifications ? `${specifications}` : "暂无商品";
        this.chousetype = specifications || "暂无商品";
      }

      this.selectPrice = showPrice || "暂无价格";
      this.setBannerList(coverImage, bannerList, htmEditorList);
    },
    /**
     * 将规格图片推到banner图的主位上
     */
    setBannerList(specsImg, bannerList, htmEditorList) {
      // if (!specsImg) return;
      this.bannerList = Utils.isNoEmptyArr(bannerList) ? [specsImg, ...bannerList] : [specsImg];
      this.goodsDetailImgList = htmEditorList
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
